import React, { useCallback, useMemo } from 'react';
import dynamic from 'next/dynamic';
import dayjs from 'dayjs';
import Link from 'next/link';

import { Project } from 'api/raffle/raffle_list_me';
import { Campaign, MyEntry } from 'api/raffle/raffle_detail';
import cryIcon from '@/assets/icons/myraffles/cry.png';
import wonIcon from 'assets/emoji/party-popper.png';
import trophyIcon from 'assets/emoji/trophy.png';
import classNames from 'classnames';
import { blockChainIcon } from 'lib/blockChain';

const Time = dynamic(() => import('./Time'), { ssr: false });
interface ICardBProps {
  type: string | undefined;
  vanity_url: string | undefined;
  project: Project;
  name: string;
  campaign: Campaign;
  winner: number | undefined;
  status: string | undefined;
  myentry: MyEntry;
  endTime: number;
  remainingSeconds: number;
  whitelist_method: string | undefined;
  blockchain: string | undefined;
}

const CardR: React.FC<ICardBProps> = ({
  campaign,
  myentry,
  name,
  project,
  status,
  vanity_url,
  winner,
  endTime,
  type,
  whitelist_method,
  remainingSeconds,
  blockchain,
}) => {
  const end = useMemo(() => {
    return remainingSeconds * 1000;
  }, [remainingSeconds]);

  const mintStatus = useMemo(() => {
    return (project?.mint_date as number) - new Date().getTime() < 0;
  }, [project?.mint_date]);

  const mintLocalFormat = useMemo(() => {
    if (!!project?.mint_date && !!project?.mint_time) {
      return dayjs.utc(project?.mint_date).local().format('MM/DD/YYYY');
    } else if (!!project?.mint_date && !project?.mint_time) {
      return dayjs(project?.mint_date).format('MM/DD/YYYY');
    } else {
      return null;
    }
  }, [project?.mint_date, project?.mint_time]);

  const handleError = (e: any) => {
    e.target.src = 'https://s.luckygo.io/luckybuy/img/defaultBannerR2.png';
  };

  const signImg = useCallback(() => {
    if (
      ((status !== 'FINALIZED' && type !== 'WALLET_COLLECTION') ||
        (status === 'ACTIVE' && type === 'WALLET_COLLECTION')) &&
      !!myentry
    ) {
      return (
        <div className="flex items-center justify-center rounded-full  border border-app bg-[rgba(0,0,0,0.6)] px-2  py-1 text-sm  font-normal text-app">
          Registered
        </div>
      );
    } else if (status === 'FINALIZED' && !!myentry && !myentry.is_win) {
      return (
        <div className="relative">
          <div className="iconShadow peer flex cursor-pointer items-center justify-center gap-1 rounded-full border border-neutral-1000 bg-[rgba(0,0,0,0.6)] px-2 py-1">
            <img src={cryIcon.src} className="w-4" alt="" />
            <div className="text-sm font-normal text-neutral-100">Lost</div>
          </div>
          <div className="absolute right-0 top-9 hidden w-[80px] rounded-lg bg-neutral-800 px-2 py-3 text-center text-sm font-normal text-neutral-200  lg:peer-hover:block">
            You lost.
          </div>
        </div>
      );
    } else if (!!myentry) {
      if (type === 'WALLET_COLLECTION' && status === 'ENDED') {
        if (whitelist_method === 'DTC') {
          return (
            <div className="relative">
              <div className="iconShadow peer  flex cursor-pointer items-center justify-center  gap-1 rounded-full border border-highlight-500 bg-[rgba(0,0,0,0.6)] px-2 py-1">
                <img src={wonIcon.src} className="w-4" alt="" />
                <div className="text-sm font-normal text-highlight-500">
                  Won
                </div>
              </div>
              <div className="absolute right-0 top-9 hidden w-[197px] rounded-lg bg-neutral-800 px-2 py-3 text-left text-sm font-normal text-neutral-200   lg:peer-hover:block">
                You submitted your wallet.
              </div>
            </div>
          );
        } else {
          return (
            <div className="relative">
              <div className="iconShadow peer  flex cursor-pointer items-center justify-center  gap-1 rounded-full border border-highlight-500 bg-[rgba(0,0,0,0.6)] px-2 py-1">
                <div>💡</div>
                <div className="text-sm font-normal text-highlight-500">
                  Won
                </div>
              </div>
              <div className="absolute right-0 top-9 hidden w-[197px] rounded-lg bg-neutral-800 px-2 py-3 text-left text-sm font-normal text-neutral-200  lg:peer-hover:block">
                You submitted your wallet, Check the raffle page for more info.
              </div>
            </div>
          );
        }
      } else if (
        status === 'FINALIZED' &&
        type !== 'WALLET_COLLECTION' &&
        myentry.is_win
      ) {
        if (whitelist_method === 'DTC') {
          return (
            <div className="relative">
              <div className="iconShadow peer  flex cursor-pointer items-center justify-center gap-1 rounded-full border border-highlight-500 bg-[rgba(0,0,0,0.6)] px-2 py-1">
                <img src={wonIcon.src} className="w-4" alt="" />
                <div className="text-sm font-normal text-highlight-500">
                  Won
                </div>
              </div>
              <div className="absolute right-0 top-9 hidden w-[197px] rounded-lg bg-neutral-800 px-2 py-3 text-left text-sm font-normal text-neutral-200  lg:peer-hover:block">
                You won！Wallets will be added direct to contract.
              </div>
            </div>
          );
        } else {
          return (
            <div className="relative">
              <div className="iconShadow peer  flex cursor-pointer items-center justify-center gap-1 rounded-full border border-highlight-500 bg-[rgba(0,0,0,0.6)] px-2 py-1">
                <div>💡</div>
                <div className="text-sm font-normal text-highlight-500">
                  Won
                </div>
              </div>
              <div className="absolute right-0 top-9 hidden w-[197px] rounded-lg bg-neutral-800 px-2 py-3 text-left text-sm font-normal text-neutral-200  lg:peer-hover:block">
                You won！Check the raffle page for more info.
              </div>
            </div>
          );
        }
      }
    }
  }, [myentry, status, type, whitelist_method]);

  const goodsDetail = useCallback(() => {
    return (
      <div>
        {type === 'FCFS' ? (
          <div>First come</div>
        ) : (
          <div>
            {(status === 'NOT_START' || status === 'ACTIVE') && (
              <div>
                Ends in <Time time={end} />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }, [end, status, type]);

  return (
    <Link href={`/r/${vanity_url}`}>
      <div className="group w-full cursor-pointer rounded-2xl bg-neutral-900">
        <div className="relative h-[140px] w-full overflow-hidden rounded-t-2xl bg-cover bg-no-repeat md:h-[160px] xl:h-[216px]">
          <img
            className="h-full w-full rounded-t-xl object-cover duration-500 group-hover:scale-110"
            src={
              !!project?.banner
                ? project.banner
                : 'https://s.luckygo.io/luckybuy/img/detailDefaultBanner2.png'
            }
            alt=""
            onError={handleError}
          />
          <div className="absolute right-3 top-3">{signImg()}</div>
          {type !== 'WALLET_COLLECTION' && (
            <div className="absolute bottom-3 right-3 flex h-6 items-center gap-1 rounded-[20px] border border-highlight-500 bg-[rgba(0,0,0,0.6)] px-2 text-highlight-500">
              <img src={trophyIcon.src} className="w-3" alt="" />
              <div className="text-sm font-normal leading-4">{winner}</div>
            </div>
          )}
        </div>

        <div className="h-full w-full p-4">
          <div className="flex items-center justify-between gap-5">
            <div
              className={classNames(
                'flex max-w-[calc(100%-48px)] items-center  gap-2 rounded-full border border-app ',
                {
                  'p-1 pr-3': !!campaign?.logo,
                  'px-3 py-1': !campaign?.logo,
                }
              )}
            >
              {!!campaign?.logo && (
                <img
                  src={campaign?.logo}
                  className="h-6 w-6 rounded-full"
                  alt=""
                  onError={(e: any) => {
                    e.target.src =
                      'https://s.luckygo.io/luckybuy/img/cardRAvatar.png';
                  }}
                />
              )}
              <div className="w-full truncate text-sm text-app">
                {campaign?.name}
              </div>
            </div>
            <img
              src={blockChainIcon(blockchain || '')}
              className="h-8 w-8 rounded-full"
              alt=""
            />
          </div>

          <div className="truncate pt-2 text-2xl font-semibold leading-8 text-neutral-100">
            {name}
          </div>

          <div className="flex items-center justify-between pt-2 text-sm font-normal text-neutral-400 ">
            {goodsDetail()}
            {!!project?.mint_date && (
              <div>
                {mintStatus ? 'Minted' : 'Mints'} on {mintLocalFormat}
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CardR;
