import React from 'react';

const SkeletonCardR = () => {
  return (
    <div className="w-full cursor-pointer rounded-2xl bg-neutral-900">
      <div className="skeleton relative h-[140px] w-full overflow-hidden rounded-t-2xl bg-cover bg-no-repeat md:h-[160px] xl:h-[216px]"></div>
      <div className="h-full w-full p-4">
        <div className="flex items-center justify-between gap-5">
          <div className="flex h-8 w-[95px] items-center justify-center gap-2 rounded-full bg-[rgba(57,66,76,0.3)]">
            <div className="skeleton h-6 w-6 rounded-full"></div>
            <div className="skeleton h-[11px] w-[47px]"></div>
          </div>
          <div className="skeleton h-7  w-7 rounded-full"></div>
        </div>
        <div className="skeleton rrounded-full my-3 h-[25px]"></div>
        <div className="flex items-center justify-between">
          <div className="skeleton h-5 w-[114px] rounded-full"></div>
          <div className="skeleton h-5 w-[150px] rounded-full"></div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonCardR;
